import Model from '@/models/Model'
import UserModel from '@/models/User'
import ProjectModel from '@/models/Project'
import RegistrationSessionModel from '@/models/RegistrationSession'
import { RegistrationTypeModel } from '@/models/RegistrationType'

const REGISTRATION_TYPES = {
  MATERIAL: 'M',
  HOURS_PROJECT: 'H',
  HOURS_GENERALLY: 'G',
}

class RegistrationModel extends Model {
  constructor(data) {
    super(data)

    this.id = Number(data.id)
    this.name = data.name
    this.amount = Number(data.amount)
    this.startAt = this.parseDateTime({
      date: data.date,
      time: data.start_at,
      hasSession: !!data.session,
    });
    this.endAt = this.parseDateTime({
      date: data.date,
      time: data.end_at,
      hasSession: !!data.session,
    });

    this.date = this.fixData(data.date)
    this.dateStr = data.date
    this.dateMoment = this.toMomentDate(data.date)

    this.userId = data.user ? data.user.id : null
    this.user = data.user ? new UserModel(data.user) : null
    this.project = data.project ? new ProjectModel(data.project) : null

    this.comment = data.comment

    this.sessionId = data.session_id
    this.session = data.session ? new RegistrationSessionModel(data.session) : null

    this.registrationTypeId = data.registration_type_id
    this.registrationType = data.registration_type
      ? new RegistrationTypeModel(data.registration_type)
      : null
  }

  parseDateTime({ date, time, hasSession }) {
    return hasSession && time
      ? this.toMomentDate(`${date} ${time}`, 'YYYY-MM-DD HH:mm')
      : time
      ? this.toMomentTime(time)
      : null;
  }
}

export default RegistrationModel
